(function ($) {
  $(function () {
    /*
     * Navbar fixed
     */
    $(window).on("scroll", function () {
      if ($(this).scrollTop() > 1) {
        $(".site-header").addClass("navbar-fixed-top");
      } else {
        $(".site-header").removeClass("navbar-fixed-top");
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $(".menu-btn-burger").on("click", function () {
      $("#nav-icon").toggleClass("open-custom-menu");
      // $("body").toggleClass("fixedPosition");
      $(".mobile-menu").toggleClass("sliding-open");
    });

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $(".scroll-to-top").addClass("show");
      } else {
        $(".scroll-to-top").removeClass("show");
        $(".scroll-to-top").addClass("animated bounceOutDown");
        $(".scroll-to-top").removeClass("animated bounceOutUp");
        $(".scroll-to-top i").css("transform", "rotateY(0) scale(1)");
      }
    });

    $(".scroll-to-top").on("click", function () {
      $(".scroll-to-top").addClass("animated bounceOutUp");
      $(".scroll-to-top i").css("transform", "rotateY(180deg) scale(1.2)");
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        1000
      );
      return false;
    });

    /*
     * Lightbox gallery
     */
    // $(".gallery-images").lightGallery({
    //   selector: ".gallery-item",
    //   share: false,
    //   download: false,
    //   rotate: false,
    //   showThumbByDefault: true,
    //   loop: false,
    //   slideEndAnimatoin: false,
    //   thumbnail: false,
    //   controls: false,
    //   allowMediaOverlap: true,
    //   toggleThumb: false,
    //   mediumZoom: false,
    //   autoplayControls: false,
    //   fullScreen: false,
    //   zoom: false,
    // });

    $("#ads-owl-carousel").owlCarousel({
      margin: 0,
      items: 1,
      loop: true,
      autoplay: true,
      mouseDrag: false,
      touchDrag: false,
      autoplayTimeout: 4500,
      dots: true,
      animateOut: "fadeOut",
      responsive: {
        0: {
          items: 1,
        },
      },
    });
    $("#navigation-owl-carousel").owlCarousel({
      margin: 0,
      items: 1,
      loop: true,
      // autoplay: true,
      autoplayTimeout: 4500,
      dots: true,
      animateOut: "fadeOut",
      responsive: {
        0: {
          items: 1,
        },
      },
    });

    /*
     * Owl carousel Explore Page
     */
    $(".my-group").each((index, element) => {
      $(".owl-explore_" + index).owlCarousel({
        margin: 20,
        items: 1,
        loop: false,
        autoplay: false,
        autoplayTimeout: 4500,
        nav: true,
        dots: false,
        animateOut: "fadeOut",
        navText: [
          `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84182 3.13517C9.04327 3.32404 9.05348 3.64046 8.86462 3.84191L5.43521 7.49994L8.86462 11.158C9.05348 11.3594 9.04327 11.6758 8.84182 11.8647C8.64036 12.0536 8.32394 12.0434 8.13508 11.8419L4.38508 7.84191C4.20477 7.64958 4.20477 7.3503 4.38508 7.15797L8.13508 3.15797C8.32394 2.95651 8.64036 2.94631 8.84182 3.13517Z" fill="black" />
        </svg>`,
          `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1584 3.13505C6.35985 2.94618 6.67627 2.95639 6.86514 3.15784L10.6151 7.15785C10.7954 7.35017 10.7954 7.64946 10.6151 7.84179L6.86514 11.8418C6.67627 12.0432 6.35985 12.0534 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1578L9.565 7.49982L6.1356 3.84179C5.94673 3.64033 5.95694 3.32391 6.1584 3.13505Z" fill="black" />
          </svg>`,
        ],
        navContainer: ".controls_" + index,
        responsive: {
          0: {
            items: 1,
            stagePadding: 50,
          },
          480: {
            items: 3,
            stagePadding: 50,
          },
        },
      });
    });

    /*
     * Owl carousel Archive Page
     */
    $(".my-group").each((index, element) => {
      $(".owl-archive_" + index).owlCarousel({
        margin: 20,
        items: 1,
        loop: false,
        autoplay: false,
        autoplayTimeout: 4500,
        nav: true,
        dots: false,
        animateOut: "fadeOut",
        navText: [
          `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84182 3.13517C9.04327 3.32404 9.05348 3.64046 8.86462 3.84191L5.43521 7.49994L8.86462 11.158C9.05348 11.3594 9.04327 11.6758 8.84182 11.8647C8.64036 12.0536 8.32394 12.0434 8.13508 11.8419L4.38508 7.84191C4.20477 7.64958 4.20477 7.3503 4.38508 7.15797L8.13508 3.15797C8.32394 2.95651 8.64036 2.94631 8.84182 3.13517Z" fill="black" />
        </svg>`,
          `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1584 3.13505C6.35985 2.94618 6.67627 2.95639 6.86514 3.15784L10.6151 7.15785C10.7954 7.35017 10.7954 7.64946 10.6151 7.84179L6.86514 11.8418C6.67627 12.0432 6.35985 12.0534 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1578L9.565 7.49982L6.1356 3.84179C5.94673 3.64033 5.95694 3.32391 6.1584 3.13505Z" fill="black" />
          </svg>`,
        ],
        navContainer: ".controls_" + index,
        responsive: {
          0: {
            items: 1,
            stagePadding: 20,
          },
          480: {
            items: 3,
            stagePadding: 50,
          },
        },
      });
    });

    // Function to check if the device is a mobile device

    // Function to check if the device is a mobile device
    function isMobileDevice() {
      return window.innerWidth <= 767;
    }

    // Initialize Owl Carousel only for mobile devices
    if (isMobileDevice()) {
      $(document).ready(function () {
        // Owl carousel Explore and Archive page
        $(".my-group").each((index, element) => {
          $(".owl-explore_" + index).owlCarousel({
            margin: 20,
            items: 1,
            loop: false,
            autoplay: false,
            autoplayTimeout: 4500,
            nav: true,
            dots: false,
            animateOut: "fadeOut",
            navText: [
              `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84182 3.13517C9.04327 3.32404 9.05348 3.64046 8.86462 3.84191L5.43521 7.49994L8.86462 11.158C9.05348 11.3594 9.04327 11.6758 8.84182 11.8647C8.64036 12.0536 8.32394 12.0434 8.13508 11.8419L4.38508 7.84191C4.20477 7.64958 4.20477 7.3503 4.38508 7.15797L8.13508 3.15797C8.32394 2.95651 8.64036 2.94631 8.84182 3.13517Z" fill="black" />
            </svg>`,
              `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1584 3.13505C6.35985 2.94618 6.67627 2.95639 6.86514 3.15784L10.6151 7.15785C10.7954 7.35017 10.7954 7.64946 10.6151 7.84179L6.86514 11.8418C6.67627 12.0432 6.35985 12.0534 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1578L9.565 7.49982L6.1356 3.84179C5.94673 3.64033 5.95694 3.32391 6.1584 3.13505Z" fill="black" />
              </svg>`,
            ],
            navContainer: ".controls_" + index,
            responsive: {
              0: {
                items: 1,
                stagePadding: 50,
              },
              480: {
                items: 3,
                stagePadding: 50,
              },
            },
          });
        });
        /*
         * Owl carousel Single Post Page
         */
        $(".single-post-grid").owlCarousel({
          margin: 20,
          items: 1,
          loop: false,
          autoplay: false,
          autoplayTimeout: 4500,
          nav: true,
          dots: false,
          animateOut: "fadeOut",
          navText: [
            `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84182 3.13517C9.04327 3.32404 9.05348 3.64046 8.86462 3.84191L5.43521 7.49994L8.86462 11.158C9.05348 11.3594 9.04327 11.6758 8.84182 11.8647C8.64036 12.0536 8.32394 12.0434 8.13508 11.8419L4.38508 7.84191C4.20477 7.64958 4.20477 7.3503 4.38508 7.15797L8.13508 3.15797C8.32394 2.95651 8.64036 2.94631 8.84182 3.13517Z" fill="black" />
        </svg>`,
            `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1584 3.13505C6.35985 2.94618 6.67627 2.95639 6.86514 3.15784L10.6151 7.15785C10.7954 7.35017 10.7954 7.64946 10.6151 7.84179L6.86514 11.8418C6.67627 12.0432 6.35985 12.0534 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1578L9.565 7.49982L6.1356 3.84179C5.94673 3.64033 5.95694 3.32391 6.1584 3.13505Z" fill="black" />
          </svg>`,
          ],
          navContainer: ".controls",
          responsive: {
            0: {
              items: 1,
              stagePadding: 20,
            },
          },
        });

        /*
         * Owl carousel Home page
         */
        $("#home-news-owl-carousel").owlCarousel({
          margin: 20,
          items: 1,
          loop: false,
          autoplay: false,
          autoplayTimeout: 4500,
          nav: true,
          dots: false,
          animateOut: "fadeOut",
          navText: [
            `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.84182 3.13517C9.04327 3.32404 9.05348 3.64046 8.86462 3.84191L5.43521 7.49994L8.86462 11.158C9.05348 11.3594 9.04327 11.6758 8.84182 11.8647C8.64036 12.0536 8.32394 12.0434 8.13508 11.8419L4.38508 7.84191C4.20477 7.64958 4.20477 7.3503 4.38508 7.15797L8.13508 3.15797C8.32394 2.95651 8.64036 2.94631 8.84182 3.13517Z" fill="black" />
        </svg>`,
            `<svg width="25" height="25" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1584 3.13505C6.35985 2.94618 6.67627 2.95639 6.86514 3.15784L10.6151 7.15785C10.7954 7.35017 10.7954 7.64946 10.6151 7.84179L6.86514 11.8418C6.67627 12.0432 6.35985 12.0534 6.1584 11.8646C5.95694 11.6757 5.94673 11.3593 6.1356 11.1578L9.565 7.49982L6.1356 3.84179C5.94673 3.64033 5.95694 3.32391 6.1584 3.13505Z" fill="black" />
          </svg>`,
          ],
          navContainer: ".controls",
          responsive: {
            0: {
              items: 1,
              stagePadding: 20,
            },
          },
        });
      });
    }

    /*
     * Mmenu light
     */
    var menu = new MmenuLight(document.querySelector("#my-menu"), "all");

    if (menu) {
      var navigator = menu.navigation({
        // selectedClass: 'Selected',
        // slidingSubmenus: true,
        // theme: "dark",
        title: "",
      });

      var drawer = menu.offcanvas({
        position: "right",
      });

      //  Open the menu.
      document.querySelector("[data-hamburger-menu]").addEventListener("click", (evnt) => {
        const isMenuOpen = drawer.wrapper.classList.contains("mm-ocd--open");
        evnt.preventDefault();

        if (isMenuOpen) {
          drawer.close();
        } else {
          drawer.open();
        }
      });

      $(".mob-menu-close").click(function () {
        drawer.close();
      });
    }

    var customBurgerMenu = $(".custom-burger-menu");

    customBurgerMenu.on("click", function () {
      $(this).toggleClass("active not-active");
      $("header nav.navbar").toggleClass("active not-active");
    });
  });

  let currentPage = 1;
  $("#load_more").on("click", function () {
    currentPage++; // Do currentPage + 1, because we want to load the next page

    $.ajax({
      type: "POST",
      url: `${window.location.origin}/wp-admin/admin-ajax.php`,
      dataType: "html",
      data: {
        action: "projects_load_more",
        paged: currentPage,
      },
      success: function (res) {
        const response = JSON.parse(res);
        if (currentPage >= response.max) {
          $("#load_more").hide();
        }
        $(".tab-pane.active .projects-grid").append(response.html);
      },
    });
  });

  $("a[data-toggle='tab']").on("shown.bs.tab", function (event) {
    if (jQuery("#all.active").length) {
      $("#load_more").show();
    } else {
      $("#load_more").hide();
    }
  });

  const shareButtons = $("[data-share-btn]");

  //  Open share menu.
  if (shareButtons.length) {
    shareButtons.each((index, element) => {
      element.addEventListener("click", (event) => {
        const shareBtn = event.target;
        const shareMenu = event.target.closest(".single-post-share-icon").querySelector("[data-share-menu]");

        shareBtn.classList.toggle("active");
        shareMenu.classList.toggle("active");
      });
    });
  }

  const copyLinks = $(".5vesti-copy-link");

  copyLinks.each((index, link) => {
    $(link).click((event) => {
      event.preventDefault();
      const url = link.getAttribute("href");
      copyToClipboard(url);
      event.target.closest(".single-post-share-icon").querySelector("[data-share-btn]").click();
    });
  });

  const openFloatingWindow = (target, url, width, height) => {
    const left = window.innerWidth - width;
    const top = 50;
    if (target !== "_blank") {
      window.open(url, "_self");
      return;
    }
    window.open(url, "floatingWindow", `width=${width}, height=${height}, top=${top}, left=${left}`);
  };

  const floatingLinks = $(".5vesti-share-link");

  floatingLinks.each((index, link) => {
    $(link).click((event) => {
      event.preventDefault();
      const url = link.getAttribute("href");
      const target = link.getAttribute("target");
      openFloatingWindow(target, url, 600, 750); // Set the desired width and height of the floating window
    });
  });

  function copyToClipboard(link) {
    const tempInput = document.createElement("input");
    tempInput.setAttribute("value", link);
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }
})(jQuery);
